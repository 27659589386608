// wiki: https://gitlab.com/bamboolab/bamboo_datatable
export class BambooDatatable {
  constructor(table, options = {}) {
    this.table = table;
    // this.className =  options.className || 'datatable';
    this.tableClassName = options.tableClassName || "js-datatable__table";
    this.theadClassName = options.theadClassName || "js-datatable__head";
    this.tbodyClassName = options.tbodyClassName || "js-datatable__body";
    this.rowClassName = options.rowClassName || "js-row";
    this.cellClassName = options.cellClassName || "js-cell";


    if (options.search) {
      this.searchClassName = options.searchClassName || "js-datatable__search";
    }

    if (options.pagination) {
      this.paginationClassName = options.paginationClassName || "js-datatable__pagination";
      this.paginationClassName = options.paginationClassName || "js-datatable__pagination";
      this.paginationTag = options.paginationTag || "a";
      this.perPage = options.perPage || 5;
      this.paginationPreviousText = options.paginationPreviousText || "&#8249;";
      this.paginationNextText = options.paginationNextText || "&#8250;";
    }

    // commonClasses are used for element creation
    this.commonClasses = {
      tableClassName: document.querySelector(`.${this.tableClassName}`).className,
      theadClassName: document.querySelector(`.${this.theadClassName}`).className,
      tbodyClassName: document.querySelector(`.${this.tbodyClassName}`).className,
      rowClassName: document.querySelector(`.${this.rowClassName}`).className,
      cellClassName: document.querySelector(`.${this.cellClassName}`).className,
    }

    if (options.search) {
      this.commonClasses.searchClassName = document.querySelector(`.${this.searchClassName}`).className;
    }

    if (options.pagination) {
      this.commonClasses.paginationClassName = document.querySelector(`.${this.paginationClassName}`).className;
    }

    this.ignore_order = "js-ignore_order";

    this.search = options.search || false;
    this.searchKeyword = "";
    this.order = options.order || false;
    this.orderByColumn = "";
    this.orderDirection = "";
    this.pagination = options.pagination || false;

    this.remote = (options.remoteUrl && options.remoteUrl.length); // add ajax call and display only returned data
    this.remoteUrl = options.remoteUrl;
    this.currentPage = options.currentPage || 1;
    this.totalPages = options.totalPages; // controller can determine the number off pages
    
    this.disableFetch = false;

    // strings for queries
    this.queries = {
      pagination: `.${this.paginationClassName}`,
      paginationNumbers: `.${this.paginationClassName} > .pagination_numbers`,
      activePaginationNumber: (page) =>
      `${this.queries.paginationNumbers}[data-show-page='${page}']`,
      searchInput: `.${this.searchClassName} > input[type=text]`,
      rows: (headOrBody) =>
      `.${this.tableClassName} > .${headOrBody} > .${this.rowClassName}`,
      cells: (ignore) => `.${this.cellClassName}:not(.${ignore})`,
      xBttn: `.${this.searchClassName} > .js-clear`,
      tBody: `.${this.tableClassName} > .${this.tbodyClassName}`,
      cell: `.${this.cellClassName}`,
      legendSpan: (spanClassName) =>
      `.${this.cellClassName}:not(.${this.ignore_order}) .${spanClassName}`,
    };

    this.columnPositions = this.getColumnPositions(); 

    this.rowType = "ul"; 
    this.celltype = "li";
    this.setRowAndCellType();

    // run main methods
    if (this.search) {
      this.addSearch();
    }
    if (this.order) {
      this.addOrder();
    }
    if (this.pagination) {
      this.addPagination();
    }
    if (this) {
      this.addMobileLabels();
    }
  }

  // MAIN METHODS
  addSearch() {
    var input = this.table.querySelector(this.queries.searchInput);
    input.onkeyup = (event) => {
      var keyword = input.value.toLowerCase();
      this.searchKeyword = keyword;
      this.runSearch();
    };
    this.addXBttn(input);
  }

  addOrder() {
    var orderRows = this.findRows(this.theadClassName);
    var orderCells = this.findOrderCells(orderRows);

    this.addDefaultOrder(orderCells);
  }

  addPagination() {
    var visibleRows = this.findRows(this.tbodyClassName);
    this.runPagination(visibleRows);
  }

  // SHARDE METHODS
  findRows(headOrBody) {
    return Array.from(
      this.table.querySelectorAll(this.queries.rows(headOrBody))
    );
  }

  getColumnPositions() {
    var positions = [];
    this.findRows(this.theadClassName).forEach((row) => {
      var columns = row.children;
      for (var i =0; i < columns.length; i++) {
        positions.push(columns[i].dataset.name)
      }
    });
    return positions;
  }

  setRowAndCellType() {
    // row and cell types are default ul and li but setRowAndCellType will find the first row and first cell and get their types
    var allRows = this.table.querySelectorAll(this.queries.rows(this.tbodyClassName));
    if (allRows && allRows.length) {
      this.rowType = allRows[0].tagName;
      var cells = allRows[0].children;
      if (cells && cells.length) {
        this.celltype = cells[0].tagName;
      }
    }
  }

  createRows(rows) {
    // used for ajax
    var visibleRows = [];
    for (let r in rows) {
      var row = rows[r];
      var rowElement = document.createElement(this.rowType);
      rowElement.className = this.commonClasses.rowClassName;

      if (row instanceof Array) {
        // create columns
        for (let c in row){
          var cell = row[c]
          var cellElement = document.createElement(this.celltype );

          if (typeof cell === 'object' && !Array.isArray(cell) && cell !== null) {
            cellElement.dataset.order = cell.order;
            cellElement.innerHTML = cell.text;
          } else {
            cellElement.innerHTML = cell;
          }

          cellElement.className = this.commonClasses.cellClassName;
          rowElement.appendChild(cellElement);  
        }
      } else {
        // create columns
        for (var c = 0; c < this.columnPositions.length; c++){
          var cell = row[this.columnPositions[c]];
          var cellElement = document.createElement(this.celltype );
          cellElement.innerHTML = cell;
          cellElement.className = this.commonClasses.cellClassName;
          rowElement.appendChild(cellElement);  
        }
      }
      visibleRows.push(rowElement);
    }
    return visibleRows;
  }

  filterVisible(element) {
    return (
      window.getComputedStyle(element).getPropertyValue("display") != "none"
    );
  }

  // METHODS FOR SEARCHING
  hideAll(arr) {
    for (var item in arr) {
      arr[item].style.display = "none";
    }
  }
  showAll(arr) {
    for (var item in arr) {
      arr[item].style.display = "";
    }
  }

  runSearch() {
    if (this.remote) {
      this.removeAllFromDom(this.queries.tBody);
      this.ajaxCall(1);  
    } else {
      var searchableRows = this.findRows(this.tbodyClassName);
      this.hideAll(searchableRows);
      var rowContainingKeyword = this.findRowContaining(searchableRows);
      this.showAll(rowContainingKeyword);
      if (this.pagination) {
        this.runPagination(rowContainingKeyword);
      }
      this.currentPage = 1;
    }
  }

  findRowContaining(searchableRows) {
    var keyword = this.searchKeyword;
    var foundRows = [];
    for (var i in searchableRows) {
      var row = searchableRows[i];
      var cells = row.querySelectorAll(this.queries.cells("ignore_search"));
      var cellsContainingKeyword = this.contains(cells, keyword);
      var cellsContainingKeywordParents = [];
      for (var j in cellsContainingKeyword) {
        var cell = cellsContainingKeyword[j];
        var safeExit = 10;
        /// climb the DOM up to the right parent (default .row)
        while (cell && safeExit > 0) {
          cellsContainingKeywordParents.unshift(cell);
          cell = cell.parentElement;
          safeExit--;

          if (cell && cell.classList.contains(`${this.rowClassName}`)) {
            foundRows.push(cell);
            break;
          }
        }
      }
    }
    return foundRows.filter(this.onlyUnique);
  }
  contains(cells, keyword) {
    /*
    contains('div', 'sometext'); // find "div" that contain "sometext"
    contains('div', /^sometext/); // find "div" that start with "sometext"
    contains('div', /sometext$/i); // find "div" that end with "sometext", case-insensitive
    */
    return Array.prototype.filter.call(cells, (cell) => {
      var cellContent = cell.textContent.replace(/ /g, "").toLowerCase();
      var keywordContent = keyword.replace(/ /g, "").toLowerCase();
      return RegExp(keywordContent).test(cellContent);
    });
  }
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  addXBttn(input) {
    var xBttn = this.table.querySelector(this.queries.xBttn);
    xBttn.onclick = (event) => {
      input.value = "";
      this.searchKeyword = "";
      this.runSearch();
    };
  }

  // METHODS FOR ORDERING
  findOrderCells(orderRows) {
    var orderRow = this.getOrderRow(orderRows);
    var cells = Array.from(
      orderRow.querySelectorAll(this.queries.cells(`${this.ignore_order}`))
    );
    for (var i in cells) {
      if (!cells[i].dataset.name) {
        cells[i].dataset.name = 'column_' + i;
      }
      this.addOrderEvent(cells[i], i);
    }
    return cells;
  }

  addOrderEvent(cell, i) {
    cell.onclick = (event) => {
      if (!this.remote) {
        this.currentPage = 1;
      }

      this.changeOrderType(cell);
      this.reorderBy(cell, i);

      this.orderDirection = cell.dataset.order;
      this.orderByColumn = cell.dataset.name;

      if (this.remote) { 
        // run sort and show pagination page 1
        this.ajaxCall(1);  
      };
    };
  }

  addDefaultOrder(orderCells) {
    for (var i in orderCells) {
      this.changeOrderType(orderCells[i]);
    }
  }

  changeOrderType(cell) {
    if (cell.dataset.order == "asc") {
      cell.dataset.order = "desc";
    } else {
      cell.dataset.order = "asc";
    }
  }

  reorderBy(cell, index) {
    var direction = cell.dataset.order;
    var index = index;

    this.addOrderLegend(cell, direction);

    var orderableRows = this.findRows(this.tbodyClassName);

    var newTBodyRows = this.getNewOrder(orderableRows, index);
    var newTBodyRows = this.applyDirection(newTBodyRows, direction);

    // need to show all for pagination
    if (this.pagination) {
      this.showAll(orderableRows);
    }

    // empty and add new rows
    this.table.querySelector(this.queries.tBody).innerHTML = "";
    for (var i in newTBodyRows) {
      var row = newTBodyRows[i];
      this.table.querySelector(this.queries.tBody).appendChild(row);
    }

    if (this.pagination) {
      this.runPagination(newTBodyRows);
    }

    if (this.search) {
      var input = this.table.querySelector(this.queries.searchInput);
      var keyword = input.value.toLowerCase();
      this.searchKeyword = keyword;
      // this.runSearch(); <-- commented bc it breaks order - makes double request
    }
  }

  getNewOrder(rows, index) {
    return rows.sort((a, b) => {
      var nameA = this.getOrderValues(a, index);
      var nameB = this.getOrderValues(b, index);
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0; // names must be equal
    });
  }

  getOrderValues(x, index) {
    var cell = x.querySelectorAll(this.queries.cell)[index];
    if (cell.dataset.order) {
      // order by custom value
      return cell.dataset.order.toUpperCase();
    } else {
      // order by text
      return cell.textContent.toUpperCase();
    }
  }

  applyDirection(rows, direction) {
    if (direction == "desc") {
      return (rows = rows.reverse());
    } else {
      return rows;
    }
  }

  addOrderLegend(cell, direction) {
    var spanClassName = "datatable__icon";

    var arrows = {
      up: "&#8593;",
      down: "&#8595;",
    };

    var orderRows = this.findRows(this.theadClassName);
    var orderRow = this.getOrderRow(orderRows);
    var legendSpans = orderRow.querySelectorAll(
      this.queries.legendSpan(spanClassName)
    );
    for (const s of legendSpans) {
      s.remove();
    }

    var legendSpan = document.createElement("span");
    legendSpan.className = spanClassName;
    if (direction == "asc") {
      legendSpan.innerHTML = arrows.down;
    } else if (direction == "desc") {
      legendSpan.innerHTML = arrows.up;
    }

    cell.appendChild(legendSpan);
    orderRows[0]
      .querySelectorAll(`.${this.cellClassName}`)
      .forEach((item) => item.classList.remove("is-active"));
    cell.classList.add("is-active");
  }

  getOrderRow(orderRows) {
    return orderRows[0];
  }


  // PAGINATION METHODS
  runPagination(rows, showPage = this.currentPage) {
    this.applyPagination(showPage, rows);
    var numberBttns = this.makePaginationBttns(rows, this.perPage);
    this.addToDOM(this.queries.pagination, numberBttns);
  }

  rmPagination() {
    var pagination = this.table.querySelector(this.queries.pagination);
    pagination.innerHTML = "";
  }

  makePaginationBttns(rows) {
    this.rmPagination();

    if (this.totalPages) {
      // controller from backend should say how many pages
      var numOfPages = this.totalPages;
    } else {
      var rowsCount = rows.length;
      var numOfPages = Math.ceil(rowsCount / this.perPage);
    }

    var bttns = [];
    // add prev bttn
    if (this.currentPage > 1) {
      bttns.push(
        this.createPaginationBttn({
          tag: this.paginationTag,
          text: this.paginationPreviousText,
          showPage: this.currentPage - 1,
          rows: rows,
        })
      );
    }

    // add number buttons
    if (numOfPages > 1) {
      for (var i = 1; i <= numOfPages; i++) {
        bttns.push(
          this.createPaginationBttn({
            tag: this.paginationTag,
            text: i,
            showPage: i,
            rows: rows,
          })
        );
      }
    }

    // add next bttn
    if (this.currentPage < numOfPages) {
      bttns.push(
        this.createPaginationBttn({
          tag: this.paginationTag,
          text: this.paginationNextText,
          showPage: this.currentPage + 1,
          rows: rows,
        })
      );
    }

    return bttns;
  }

  createPaginationBttn(options) {
    var bttn = document.createElement(options.tag);
    bttn.innerHTML = options.text;
    bttn.className = "pagination_numbers";
    bttn.dataset.showPage = options.showPage;
    if (options.showPage == this.currentPage) {
      bttn.classList.add("is-active");
    }
    bttn.onclick = (event) => {
      if (this.remote) {
        this.ajaxCall(options.showPage);
      } else {
        this.runPagination(options.rows, options.showPage);
      }
    };
    return bttn;
  }

  addToDOM(target, elementsToAdd) {
    var container = this.table.querySelector(target);
    for (var i in elementsToAdd) {
      var element = elementsToAdd[i];
      container.appendChild(element);
    }
  }

  removeAllFromDom(tBody) {
    document.querySelectorAll(tBody).forEach((item) => item.innerHTML = "");
  }

  applyPagination(page, rows = this.findRows(this.tbodyClassName)) {
    this.hideAll(rows);
    var start = (page - 1) * this.perPage;
    var end = start + this.perPage;
    var currentPageRows = rows.slice(start, end);
    this.showAll(currentPageRows);
    this.currentPage = page;
  }

  applyRemotePagination(page, rows = this.findRows(this.tbodyClassName)) {
    this.removeAllFromDom(this.queries.tBody);
    for (let r in rows) {
      document.querySelectorAll(this.queries.tBody).forEach((item) => item.appendChild(rows[r]));
    }

    this.currentPage = page;
  }


  // AJAX methods
  ajaxCall(nextPage=null) {
    // break if a fetch is running
    if (this.disableFetch) {
      return false
    }
    this.disableFetch = true

    this.disableFetch = true;
    var showPage = nextPage ? nextPage : this.currentPage+1;

    //      console.log(this.orderByColumn)
    fetch(`${this.remoteUrl}?current_page=${showPage}&order_by_column=${this.orderByColumn}&order_direction=${this.orderDirection}&search_keyword=${this.searchKeyword}`, {
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json"
      },
      credentials: 'same-origin',
      method: 'get',
    })
      .then(response => response.json())
      .then(jsonData => {
        this.disableFetch = false;
        this.perPage = jsonData.perPage || this.perPage;
        this.totalPages = parseInt(jsonData.totalPages) || this.totalPages;
        var visibleRows = this.createRows(jsonData.data);

        var newCurrentPage = parseInt(jsonData.currentPage) || this.currentPage;
        this.applyRemotePagination(newCurrentPage, visibleRows);

        var numberBttns = this.makePaginationBttns(visibleRows);
        this.addToDOM(this.queries.pagination, numberBttns);
      })
      .catch(err => {
        //error block
      });
  }

  addMobileLabels(selector) {}
};

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import hljs from 'highlight.js'

import I18n from 'i18n-js'
window.I18n = I18n;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

document.addEventListener('turbolinks:load', () => {
  hljs.highlightAll();
})

require("./custom/loading");
require("./custom/bamboo_datatable_init");
require('./custom/utilities');
require("./custom/modal");
require("./custom/nested_fields");
require("./custom/image_upload_preview");
require("./custom/document_upload_preview");
require('./custom/notifications');
require("./custom/confirmation_dialog");
require("./custom/char_counter");
require("./custom/translateable_tabs");
require("./custom/action_logs");
require("./custom/tinymce");
require("./custom/datetimepicker");
require("./custom/sorting");
require("./custom/remote_links_fixer");
require("./custom/server_notice");

import { Controller } from "@hotwired/stimulus"

export default class AccordionController extends Controller {
  static targets = ["trigger", "content"]

  connect() {
    this._init();
  }

  closeAllNodes() {
    this.triggerTargets.forEach((target) => {
      target.classList.remove('is-active');
    });

    this.contentTargets.forEach((target) => {
      target.classList.remove('is-active');
    });
  }

  openNode(index) {
    let triggers = this._getTriggerTargetsByIndex(index);
    let contents = this._getContentTargetsByIndex(index);
    let isCollapsible = this.data.get('collapsible') == 'true';

    if (triggers.length && contents.length) {
      if (isCollapsible) {
        this.closeAllNodes();
      }

      triggers.forEach((trigger) => {
        trigger.classList.add('is-active');
      });

      contents.forEach((content) => {
        content.classList.add('is-active');
      });
    }
  }

  closeNode(index) {
    let triggers = this._getTriggerTargetsByIndex(index);
    let contents = this._getContentTargetsByIndex(index);

    if (triggers.length && contents.length) {
      triggers.forEach((trigger) => {
        trigger.classList.remove('is-active');
      });

      contents.forEach((content) => {
        content.classList.remove('is-active');
      });
    }
  }

  _getTriggerTargetsByIndex(index) {
    return this.triggerTargets.filter((trigger) => {
      return trigger.dataset.index == index;
    });
  }

  _getContentTargetsByIndex(index) {
    return this.contentTargets.filter((content) => {
      return content.dataset.index == index;
    });
  }

  _init() {
    this.closeAllNodes();
    this._initTriggerEvents();

    if (isDevEnvironment()) {
      console.info(`[DEV] Accordion Initialized`);
    }
  }

  _initTriggerEvents() {
    this.triggerTargets.forEach((target) => {
      target.addEventListener('click', () => {
        let index = target.dataset.index;
        let isActive = target.classList.contains('is-active');

        if (index) {
          isActive ? this.closeNode(index) : this.openNode(index);
        }
      })
    })
  }
}

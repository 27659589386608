// Source: https://derk-jan.com/2020/10/rails-ujs-custom-confirm/

import Rails from "@rails/ujs";

// This is the native confirm, showing a browser alert/confirm dialog
const nativeConfirm = Rails.confirm;

let __SkipConfirmation = false;

Rails.confirm = function (message, element) {
  if (__SkipConfirmation) {
    return true;
  }

  let onTrue = function onConfirm() {
    __SkipConfirmation = true;
    element.click();
    __SkipConfirmation = false;
  };

  askQuestion(message, I18n.t('yes'), I18n.t('no'), onTrue);

  return false;
};

// https://izitoast.marcelodolza.com/

import iziToast from "izitoast";

window.dispatchInfoToast = function (message) {
  let infoColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--color--info")
    .trim();
  let lightColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--color--foreground--inverted")
    .trim();
  iziToast.info({
    title: "",
    message: message,
    backgroundColor: infoColor,
    icon: "",
    titleColor: lightColor,
    messageColor: lightColor,
    theme: "dark",
  });

  if (isDevEnvironment()) {
    console.info(`[DEV] Info Toast Dispatched: "${message}"`);
  }
};

window.dispatchSuccessToast = function (message) {
  let successColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--color--success")
    .trim();
  let darkColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--color--foreground--inverted")
    .trim();
  iziToast.success({
    title: "",
    message: message,
    backgroundColor: successColor,
    icon: "",
    titleColor: darkColor,
    messageColor: darkColor,
  });

  if (isDevEnvironment()) {
    console.info(`[DEV] Success Toast Dispatched: "${message}"`);
  }
};

window.dispatchWarningToast = function (message) {
  let warningColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--color--warning")
    .trim();
  let darkColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--color--foreground")
    .trim();
  iziToast.warning({
    title: "",
    message: message,
    backgroundColor: warningColor,
    icon: "",
    titleColor: darkColor,
    messageColor: darkColor,
  });

  if (isDevEnvironment()) {
    console.info(`[DEV] Warning Toast Dispatched: "${message}"`);
  }
};

window.dispatchAlertToast = function (message) {
  let dangerColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--color--alert")
    .trim();
  let lightColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--color--foreground--inverted")
    .trim();
  iziToast.error({
    title: "",
    message: message,
    backgroundColor: dangerColor,
    icon: "",
    titleColor: lightColor,
    messageColor: lightColor,
    theme: "dark",
  });

  if (isDevEnvironment()) {
    console.info(`[DEV] Alert Toast Dispatched: "${message}"`);
  }
};

window.dispatchDevNotice = function (title, message) {
  let dangerColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--color--alert")
    .trim();
  let lightColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--color--foreground--inverted")
    .trim();

  iziToast.error({
    title: title,
    message: message,
    backgroundColor: dangerColor,
    icon: "",
    titleColor: lightColor,
    messageColor: lightColor,
    theme: "dark",
    timeout: false,
    progressBar: false,
    position: "bottomCenter",
  });

  if (isDevEnvironment()) {
    console.info(`[DEV] Dev Notice Dispatched: "${title} - ${message}"`);
  }
};

window.askQuestion = function (
  message,
  trueButton = "Yes",
  falseButton = "No",
  onTrue = function () {
    return true;
  },
  onFalse = function () {
    return false;
  }
) {
  let darkColor60 = getComputedStyle(document.documentElement)
    .getPropertyValue("--color--dark-60")
    .trim();
  let darkColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--color--dark")
    .trim();
  let lightColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--color--light")
    .trim();

  iziToast.question({
    title: "",
    message: message,
    backgroundColor: darkColor,
    icon: "",
    titleColor: lightColor,
    messageColor: lightColor,
    theme: "dark",
    timeout: false,
    progressBar: false,
    position: "center",
    close: false,
    overlay: true,
    overlayColor: darkColor60,
    buttons: [
      [
        `<button><b>${trueButton}</b></button>`,
        function (instance, toast) {
          instance.hide({ transitionOut: "fadeOut" }, toast, "button");
          onTrue();
        },
        true,
      ],
      [
        `<button><b>${falseButton}</b></button>`,
        function (instance, toast) {
          instance.hide({ transitionOut: "fadeOut" }, toast, "button");
          onFalse();
        },
        true,
      ],
    ],
  });

  if (isDevEnvironment()) {
    console.info(`[DEV] Question Toast Dispatched: "${message}"`);
  }
};

$(document).on("change", ".upload_image", function(e){
  var $input = e.target;
  var $image_preview = $(".js-input-field-preview:eq("+$($input).index(".upload_image")+")");
  var max_mb_file_size = $input.dataset.maxFileSize ? parseInt($input.dataset.maxFileSize) : false;

  if ($input.files && $input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $image_preview.attr('src', e.target.result)

      if(max_mb_file_size && $input.files[0].size > max_mb_file_size * 1000000){
        $input.value = "";
        $image_preview.attr('src', "");
        dispatchWarningToast(`${I18n.t('image_upload.file_too_big')} ${max_mb_file_size}Mb`);
      }
    };

    reader.readAsDataURL($input.files[0]);
  }
});

$(document).on('click', '.js-input-field-preview', function(){
  $(".upload_image:eq("+$(this).index(".js-input-field-preview")+")").click();
})

/* ------USAGE------ *\
  IN FORM:
  <div class="field is-fullwidth">
    <%= f.label :avatar %>
    <div class="image-input is-avatar-image <%= 'is-invalid' if f.object.errors[:avatar].any? %>">
      <%= f.file_field :avatar, class: 'upload_image', accept: accept_file_extensions_for(f.object.class.uploaders[:avatar]), data: { max_file_size: 10 } %>
      <img src="" class="js-input-field-preview">
      <img src="<%= f.object.avatar_url %>">
      <%= f.hidden_field :avatar_cache %>
    </div>
    <%= error_placeholder(f.object, :avatar) %>
  </div>

  IN admin/components/_images.scss:
  .image-input {
    &.is-invalid {
      @include set-accent(alert);
      border-color: var(--color--accent);
    }

    ADD THIS PART:
    &.is-avatar-image {
      width: 100%;
      max-width: 250px;
      aspect-ratio: 1 / 1;
    }
  }
\* ----------------- */

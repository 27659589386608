window.initTranslateableTabs = function(){
	$('.translateable-fields:not(.tabbed)').each(function(){
		$fieldsWrapper = $(this);
		$fieldsWrapper.addClass('tabs');
		$fieldsWrapper.wrapInner('<div class="tabs__content-wrapper p-2">');
		$fieldsWrapper.prepend(`<div class='tabs__button-wrapper'></div>`);
		$fieldsWrapper.find('div[data-locale]').each(function(){
			$languageTab = $(`<button type='button' value='${$(this).data('locale')}' class='tabs__button'>${I18n.t(`lang.${$(this).data('locale')}`)}</button>`);
			$fieldsWrapper.find('.tabs__button-wrapper').append($languageTab);
		})
	})

	$('.translateable-fields:not(.tabbed)').each(function(){
		if($(this).find('.error-message').length){

				$(this).find('.error-message').each(function(){
					$(this).closest('div[data-locale]').addClass('has-errors');
				})

				$('div[data-locale]').each(function(index){
					if($(this).hasClass('has-errors')){
						$(this).closest('.translateable-fields').find(`.tabs__button:eq(${index})`).addClass('has-errors');
					}
				})

				$(this).find('button.tabs__button').removeClass('is-active');
				$(this).find('div[data-locale]').addClass('is-hidden');

				$(this).find('button.tabs__button.has-errors:first').addClass('is-active');
				$(this).find('div[data-locale].has-errors:first').removeClass('is-hidden');			
		} else {
			$(this).find('div[data-locale]:not(:first)').addClass('is-hidden');
			$(this).find('button.tabs__button:first').addClass('is-active');
		}
		$(this).addClass('tabbed');
	})

	$(document).on('click', '.tabs__button:not(.is-active)', function(){
		$(this).closest('.translateable-fields').find('.tabs__button').removeClass('is-active');
		$(this).closest('.translateable-fields').find('div[data-locale]').addClass('is-hidden');
		$(this).closest('.translateable-fields').find(`div[data-locale='${$(this).val()}']`).removeClass('is-hidden');
		$(this).addClass('is-active');
	})
}

/* USAGE:
	1. Wrap translation fields in <div class='translateable-fields tabs'>
	2. Wrap every translation fields in <div data-locale="<%= f.object.locale %>">
	3. On form page initialize with initTranslateableTabs();

	#posts/_form.html.erb:
	<div class="translateable-fields tabs">
		<%= f.fields_for :post_translations do |ff| %>
			<%= render 'post_translation_fields', f: ff %>
		<% end %>
	</div>
	<script>
		initTranslateableTabs();
	</script>

	#posts/_post_translation_fields.html.erb:
	<div data-locale="<%= f.object.locale %>">
		<%= f.hidden_field :locale %>

		... other fields here
	</div>
*/

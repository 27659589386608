import tinymce from 'tinymce/tinymce';
import 'tinymce/plugins/table';
import 'tinymce/plugins/table';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/code';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/save';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/textpattern';

import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default';

import '../modules/tinymce/langs/hr.js'

function image_upload_handler (blobInfo, success, failure, progress) {
  var xhr, formData;

  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open('POST', 'tinymce-images');

  xhr.upload.onprogress = function (e) {
    progress(e.loaded / e.total * 100);
  };

  xhr.onload = function() {
    var json;

    if (xhr.status === 403) {
      failure('HTTP Error: ' + xhr.status, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure('HTTP Error: ' + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.location != 'string') {
      failure('Invalid JSON: ' + xhr.responseText);
      return;
    }

    success(json.location);
  };

  xhr.onerror = function () {
    failure(`${I18n.t('tinymce_errors.image_xhr_error')}` + xhr.status);
  };

  formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
};

/* EDITOR TYPES */

window.tinyMce = function(language=null) {
  tinymce.remove();

  tinymce.init({
    selector: "textarea.tinymce",
    content_style: "body { font-family: Arial; }",
    language: language || I18n.locale || 'en',
    relative_urls: false,
    theme : "silver",
    skin: false,
    content_css: false,
    navbar: false,
    extended_valid_elements: "script[type|src]",
    images_upload_handler: image_upload_handler,
    toolbar: ["attachment | styleselect | link unlink | image media table | undo redo","bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor | fullscreen preview"],
    plugins: "table,fullscreen,image,link,preview,autolink,advlist,lists,charmap,hr,anchor,pagebreak,searchreplace,wordcount,visualblocks,visualchars,code,insertdatetime,media,nonbreaking,save,contextmenu,directionality,paste,textcolor,colorpicker,textpattern",
    height: "500",
  });
  tinymce.editors = [];
}

window.tinyMceText = function(language=null) {
  tinymce.remove();

  tinymce.init({
    selector: "textarea.tinymce-text",
    content_style: "body { font-family: Arial; }",
    language: language || I18n.locale || 'en',
    relative_urls: false,
    menubar: false,
    resize: false,
    toolbar: " undo redo | bold italic | alignleft aligncenter alignright alignjustify | outdent indent",
    height: "200"
  })
}

window.tinyMceMinimal = function(language=null) {
  tinymce.remove();

  tinymce.init({
    selector: "textarea.tinymce-minimal",
    content_style: "body { font-family: Arial; }",
    language: language || I18n.locale || 'en',
    relative_urls: false,
    menubar: false,
    resize: false,
    toolbar: " undo redo | bold italic",
    height: "200"
  })
}

import { Controller } from "@hotwired/stimulus"
import { Splide } from "@splidejs/splide";

export default class TabsController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    const element = this.element;
    const splide = new Splide(element);
    splide.mount()

    if (isDevEnvironment()) {
      console.info(`[DEV] Splide Initialized`);
    }
  }
}

$( document ).on('turbolinks:load', function() {
  initCharCounters();
})

$(document).on('input focus blur', "[data-char-max]", function(e){
	countChars(this);
});

window.countChars = function(input) {
	let max = parseInt($(input).attr('data-char-max'));
	let current = $(input).val().length;
	if($(`.char-counter-${input.id}`).length == 0){
		$(input).after(`<span class='char-counter char-counter-${input.id}'></span>`);
	}
	let percentage = (current / max).toFixed(3);
	$(`.char-counter-${input.id}`).css('color', counterColor(percentage));
	$(`.char-counter-${input.id}`).text(`${current}/${max}`);
}

window.initCharCounters = function(input) {
	$('[data-char-max]').each(function(){
		countChars(this);
	})
}

window.counterColor = function(ratio) {
	if(ratio > 1){
		return '#b71c1c';
	}
	if(ratio < 0.9){
		return 'initial';
	}
  return '#f57f17';
}

//USAGE
/*
<%= f.text_field :some_limited_input, data: { char_max: 80 } %>
*/
import { BambooDatatable } from "../modules/bamboo_datatable"

document.addEventListener("turbolinks:load", () => {
  let datatables = document.querySelectorAll(".js-datatable");

  if (datatables) {
    datatables.forEach((datatable) => {
      var table = new BambooDatatable(datatable, {
        search: true,
        order: true,
        pagination: false,
      });

      if (isDevEnvironment() && table) {
        console.info(`[DEV] Bamboo Datatable Initialized`);
      }
    });
  }
});

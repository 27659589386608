$(document).on('click', '.remove_fields', function(e){
  e.preventDefault();
  $(this).closest("fieldset").find('input[type=hidden][name$="[_destroy]"]').val(1);
  $(this).closest('fieldset').hide();
});

$(document).on('click', '.add_nested_fields', function(e){
  e.preventDefault();
  var time=new Date().getTime();
  var regexp=new RegExp($(this).data('id'),'g');
  $(this).before($(this).data('fields').replace(regexp,time));
});

import { Controller } from "@hotwired/stimulus"

export default class TabsController extends Controller {
  static targets = ["trigger", "content"]

  connect() {
    this._init();
  }

  openTab(index) {
    this.triggerTargets.forEach(target => {
      if (target.dataset.index == index) {
        target.classList.add('is-active');
      } else {
        target.classList.remove('is-active');
      }
    })

    this.contentTargets.forEach(target => {
      if (target.dataset.index == index) {
        target.classList.add('is-active');
      } else {
        target.classList.remove('is-active');
      }
    })
  }

  _init() {
    if(this.triggerTargets.length > 0) {
      this.openTab(this.triggerTargets[0].dataset.index);
    }
    this._initTriggerEvents();

    if (isDevEnvironment()) {
      console.info(`[DEV] Tabs Initialized`);
    }
  }

  _initTriggerEvents() {
    this.triggerTargets.forEach((target) => {
      target.addEventListener('click', () => {
        this.openTab(target.dataset.index);
      })
    })
  }
}

$(document).on("change", ".upload_document", function(e){
  var $input = e.target;
  var $document_preview = $(".js-document-preview:eq("+$($input).index(".upload_document")+")");
  $document_preview.text($input.files[0].name.replace(/ /g,"_"));
});

$(document).on('click', '.js-upload-document', function(e){
	$(".upload_document:eq("+$(e.target).index(".js-upload-document")+")").click();
})

/* ------USAGE------ *\
  <div class="form__field">
    <%= f.label :document %>
    <%= f.hidden_field :document_cache %>
    <%= f.file_field :document, class: 'upload_document', accept: accept_file_extensions_for(f.object.class.uploaders[:document]) %>
    <button type="button" class="js-upload-document">
      <%= t('document_upload.upload_document') %>
    </button>
    <em><p class="js-document-preview"><%= f.object.document_cache&.split("/")&.last || f.object.document.identifier %></p></em>
  </div>
\* ----------------- */

/* FOR TOMO TO STYLE - paste this anywhere and delete this comment after *\

  <div class="form__field">
    <label for="admin_document">Document</label>
    <input type="hidden" name="admin[document_cache]" id="admin_document_cache">
    <input class="upload_document" accept=".pdf,.doc,.docx" type="file" name="admin[document]" id="admin_document">
    <button type="button" class="js-upload-document">Učitaj dokument</button>
    <em><p class="js-document-preview">corp_hosp_merch_processes-drawio.pdf</p></em>
  </div>

\* LLBL */

tinymce.addI18n('hr',{
  "Redo": "Vrati",
  "Undo": "Poni\u0161ti",
  "Cut": "Izre\u017ei",
  "Copy": "Kopiraj",
  "Paste": "Zalijepi",
  "Select all": "Ozna\u010di sve",
  "New document": "Novi dokument",
  "Ok": "U redu",
  "Cancel": "Odustani",
  "Visual aids": "Vizualna pomo\u0107",
  "Bold": "Podebljano",
  "Italic": "Kurziv",
  "Underline": "Crta ispod",
  "Strikethrough": "Crta kroz sredinu",
  "Superscript": "Eksponent",
  "Subscript": "Indeks",
  "Clear formatting": "Ukloni oblikovanje",
  "Align left": "Poravnaj lijevo",
  "Align center": "Poravnaj po sredini",
  "Align right": "Poravnaj desno",
  "Justify": "Obostrano poravnanje",
  "Bullet list": "Lista",
  "Numbered list": "Numerirana lista",
  "Decrease indent": "Smanji uvla\u010denje",
  "Increase indent": "Pove\u0107aj uvla\u010denje",
  "Close": "Zatvori",
  "Formats": "Formati",
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "Va\u0161 preglednik ne podr\u017eava direktan pristup me\u0111uspremniku. Molimo Vas da umjesto toga koristite tipkovni\u010dke kratice Ctrl+X\/C\/V.",
  "Headers": "Zaglavlja",
  "Header 1": "Zaglavlje 1",
  "Header 2": "Zaglavlje 2",
  "Header 3": "Zaglavlje 3",
  "Header 4": "Zaglavlje 4",
  "Header 5": "Zaglavlje 5",
  "Header 6": "Zaglavlje 6",
  "Headings": "Naslovi",
  "Heading 1": "Naslov 1",
  "Heading 2": "Naslov 2",
  "Heading 3": "Naslov 3",
  "Heading 4": "Naslov 4",
  "Heading 5": "Naslov 5",
  "Heading 6": "Naslov 6",
  "Div": "DIV",
  "Pre": "PRE",
  "Code": "CODE oznaka",
  "Paragraph": "Paragraf",
  "Blockquote": "BLOCKQUOTE",
  "Inline": "Unutarnje",
  "Blocks": "Blokovi",
  "Align": "Poravnanje",
  "Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "Akcija zalijepi od sada lijepi \u010disti tekst. Sadr\u017eaj \u0107e biti zaljepljen kao \u010disti tekst sve dok ne isklju\u010dite ovu opciju.",
  "Font Family": "Obitelj fonta",
  "Font Sizes": "Veli\u010dine fonta",
  "Class": "Class",
  "Browse for an image": "Browse for an image",
  "OR": "OR",
  "Drop an image here": "Drop an image here",
  "Upload": "Upload",
  "Default": "Zadano",
  "Circle": "Krug",
  "Disc": "To\u010dka",
  "Square": "Kvadrat",
  "Lower Alpha": "Mala slova",
  "Lower Greek": "Mala gr\u010dka slova",
  "Lower Roman": "Mala rimska slova",
  "Upper Alpha": "Velika slova",
  "Upper Roman": "Velika rimska slova",
  "Anchor": "Sidro",
  "Anchor...": "Sidro...",
  "Name": "Ime",
  "Id": "Id",
  "Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "Id treba po\u010dinjati slovom, a nakon toga slijede samo slova, brojevi, crtice, to\u010dke, dvoto\u010dke i podvlake.",
  "You have unsaved changes are you sure you want to navigate away?": "Postoje ne pohranjene izmjene, jeste li sigurni da \u017eelite oti\u0107i?",
  "Restore last draft": "Vrati posljednju skicu",
  "Special character": "Poseban znak",
  "Special character...": "Poseban znak...",
  "Source code": "Izvorni kod",
  "Insert\/Edit code sample": "Umetni\/Uredi primjer k\u00f4da",
  "Language": "Jezik",
  "Color": "Boja",
  "R": "R",
  "G": "G",
  "B": "B",
  "Left to right": "S lijeva na desno",
  "Right to left": "S desna na lijevo",
  "Emoticons": "Emotikoni",
  "Document properties": "Svojstva dokumenta",
  "Title": "Naslov",
  "Keywords": "Klju\u010dne rije\u010di",
  "Description": "Opis",
  "Robots": "Roboti pretra\u017eiva\u010da",
  "Author": "Autor",
  "Encoding": "Kodna stranica",
  "Fullscreen": "Cijeli ekran",
  "Action": "Action",
  "Shortcut": "Shortcut",
  "Help": "Help",
  "Address": "Address",
  "Focus to menubar": "Focus to menubar",
  "Focus to toolbar": "Focus to toolbar",
  "Focus to element path": "Focus to element path",
  "Focus to contextual toolbar": "Focus to contextual toolbar",
  "Insert link (if link plugin activated)": "Insert link (if link plugin activated)",
  "Save (if save plugin activated)": "Save (if save plugin activated)",
  "Find (if searchreplace plugin activated)": "Find (if searchreplace plugin activated)",
  "Plugins installed ({0}):": "Plugins installed ({0}):",
  "Premium plugins:": "Premium plugins:",
  "Learn more...": "Learn more...",
  "You are using {0}": "You are using {0}",
  "Horizontal line": "Horizontalna linija",
  "Insert\/edit image": "Umetni\/izmijeni sliku",
  "Image description": "Opis slike",
  "Source": "Izvor",
  "Dimensions": "Dimenzije",
  "Constrain proportions": "Zadr\u017ei proporcije",
  "General": "Op\u0107enito",
  "Upload": "Učitavanje",
  "Drop an image here": "Ispustite sliku ovdje",
  "Browse for an image": "Učitaj sa uređaja",
  "Alternative description": "Alternativni opis (ALT)",
  "Advanced": "Napredno",
  "Style": "Stil",
  "Vertical space": "Okomit razmak",
  "Horizontal space": "Horizontalan razmak",
  "Border": "Rub",
  "Insert image": "Umetni sliku",
  "Image": "Slika",
  "Image list": "Image list",
  "Rotate counterclockwise": "Rotiraj lijevo",
  "Rotate clockwise": "Rotiraj desno",
  "Flip vertically": "Obrni vertikalno",
  "Flip horizontally": "Obrni horizontalno",
  "Edit image": "Uredi sliku",
  "Image options": "Opcije slike",
  "Zoom in": "Pove\u0107aj",
  "Zoom out": "Smanji",
  "Crop": "Obre\u017ei",
  "Resize": "Promjeni veli\u010dinu",
  "Orientation": "Orijentacija",
  "Brightness": "Svjetlina",
  "Sharpen": "Izo\u0161travanje",
  "Contrast": "Kontrast",
  "Color levels": "Razine boje",
  "Gamma": "Gamma",
  "Invert": "Invertiraj",
  "Apply": "Primijeni",
  "Back": "Natrag",
  "Insert date\/time": "Umetni datum\/vrijeme",
  "Date\/time": "Datum\/vrijeme",
  "Insert link": "Umetni poveznicu",
  "Insert\/edit link": "Umetni\/izmijeni poveznicu",
  "Text to display": "Tekst za prikaz",
  "Url": "Url",
  "Target": "Meta",
  "None": "Ni\u0161ta",
  "New window": "Novi prozor",
  "Remove link": "Ukloni poveznicu",
  "Anchors": "Kra\u0107e poveznice",
  "Link": "Link",
  "Paste or type a link": "Zalijepi ili upi\u0161i link",
  "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "Izgleda da je URL koji ste upisali e-mail adresa. \u017delite li dodati obavezan mailto: prefiks?",
  "The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "Izgleda da je URL koji ste upisali vanjski link. \u017delite li dodati obavezan http:\/\/ prefiks?",
  "Link list": "Link list",
  "Insert video": "Umetni video",
  "Insert\/edit video": "Umetni\/izmijeni video",
  "Insert\/edit media": "Umetni\/uredi mediju",
  "Alternative source": "Alternativni izvor",
  "Poster": "Poster",
  "Paste your embed code below:": "Umetnite va\u0161 kod za ugradnju ispod:",
  "Embed": "Ugradi",
  "Media": "Media",
  "Nonbreaking space": "Neprekidaju\u0107i razmak",
  "Page break": "Prijelom stranice",
  "Paste as text": "Zalijepi kao tekst",
  "Preview": "Pregled",
  "Print": "Ispis",
  "Save": "Spremi",
  "Find": "Tra\u017ei",
  "Replace with": "Zamijeni s",
  "Replace": "Zamijeni",
  "Replace all": "Zamijeni sve",
  "Prev": "Prethodni",
  "Next": "Slijede\u0107i",
  "Find and replace": "Prona\u0111i i zamijeni",
  "Could not find the specified string.": "Tra\u017eeni tekst nije prona\u0111en",
  "Match case": "Pazi na mala i velika slova",
  "Whole words": "Cijele rije\u010di",
  "Spellcheck": "Provjeri pravopis",
  "Ignore": "Zanemari",
  "Ignore all": "Zanemari sve",
  "Finish": "Zavr\u0161i",
  "Add to Dictionary": "Dodaj u rje\u010dnik",
  "Insert table": "Umetni tablicu",
  "Table properties": "Svojstva tablice",
  "Delete table": "Izbri\u0161i tablicu",
  "Cell": "Polje",
  "Row": "Redak",
  "Column": "Stupac",
  "Cell properties": "Svojstva polja",
  "Merge cells": "Spoji polja",
  "Split cell": "Razdvoji polja",
  "Insert row before": "Umetni redak prije",
  "Insert row after": "Umetni redak nakon",
  "Delete row": "Izbri\u0161i redak",
  "Row properties": "Svojstva redka",
  "Cut row": "Izre\u017ei redak",
  "Copy row": "Kopiraj redak",
  "Paste row before": "Umetni redak prije",
  "Paste row after": "Umetni redak nakon",
  "Cut column": "Izre\u017ei stupac",
  "Copy column": "Kopiraj stupac",
  "Paste column before": "Umetni stupac prije",
  "Paste column after": "Umetni stupac nakon",
  "Insert column before": "Umetni stupac prije",
  "Insert column after": "Umetni stupac nakon",
  "Delete column": "Izbri\u0161i stupac",
  "Cols": "Stupci",
  "Rows": "Redci",
  "Width": "\u0160irina",
  "Height": "Visina",
  "Cell spacing": "Razmak izme\u0111u polja",
  "Cell padding": "Razmak unutar polja",
  "Caption": "Natpis",
  "Left": "Lijevo",
  "Center": "Sredina",
  "Right": "Desno",
  "Cell type": "Vrsta polja",
  "Scope": "Doseg",
  "Alignment": "Poravnanje",
  "H Align": "H Poravnavanje",
  "V Align": "V Poravnavanje",
  "Top": "Vrh",
  "Line height": "Visina linije",
  "Middle": "Sredina",
  "Bottom": "Dno",
  "Header cell": "Polje zaglavlja",
  "Row group": "Grupirani redci",
  "Column group": "Grupirani stupci",
  "Row type": "Vrsta redka",
  "Header": "Zaglavlje",
  "Body": "Sadr\u017eaj",
  "Footer": "Podno\u017eje",
  "Border color": "Boja ruba",
  "Insert template": "Umetni predlo\u017eak",
  "Templates": "Predlo\u0161ci",
  "Template": "Predlo\u017eak",
  "Text color": "Boja teksta",
  "Background color": "Boja pozadine",
  "Custom...": "Prilago\u0111eno...",
  "Custom color": "Prilago\u0111ena boja",
  "Remove color": "Ukloni boju",
  "No color": "Bez boje",
  "Table of Contents": "Sadr\u017eaj",
  "Show blocks": "Prika\u017ei blokove",
  "Show invisible characters": "Prika\u017ei nevidljive znakove",
  "Words: {0}": "Rije\u010di: {0}",
  "Word count": "Prebrojavanje riječi",
  "Count": "Prebrojavanje",
  "Words": "Riječi",
  "Characters": "Znakovi",
  "Characters (no spaces)": "Znakovi (bez razmaka)",
  "Document": "Dokument",
  "Selection": "Označeno",
  "File": "Datoteka",
  "Edit": "Izmijeni",
  "Insert": "Umetni",
  "View": "Pogled",
  "Format": "Oblikuj",
  "Table": "Tablica",
  "Tools": "Alati",
  "Find and replace...": "Pronađi i zamijeni...",
  "Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "Pritisni ALT-F9 za izbornik. Pritisni ALT-F10 za alatnu traku. Pritisni ALT-0 za pomo\u0107",
  "Add document": "Ubaci datoteku"
});
window.dispatchModal = (content, options = {}) => {
  setModalSize(options.size);
  setModalContent(content, options);
  openModal();

  if (isDevEnvironment()) {
    console.info(`[DEV] Modal dispatched`);
  }
}

window.setModalSize = (size = 'medium') => {
  let modalWrapper = document.querySelector('.modal__wrapper');
  modalWrapper.classList.remove('modal__wrapper--small', 'modal__wrapper--medium', 'modal__wrapper--large');
  modalWrapper.classList.add(`modal__wrapper--${size}`);
}

window.setModalContent = (content, options = {}) => {
  let modalContent = document.querySelector('.js-modal-content');
  modalContent.innerHTML = content;
  //initChoices(modalContent.querySelectorAll('.js-select'));

  if (isDevEnvironment()) {
    console.info(`[DEV] Modal content set:`, content);
  }
}

window.openModal = () => {
  let modal = document.querySelector('.js-modal');
  let modalContent = document.querySelector('.js-modal-content');
  document.body.classList.add('modal-is-open');
  modal.hidden = false;
  modalContent.focus();

  if($(modalContent).find('input')){
    $(modalContent).find('input:first').focus();
  }

  document.onkeydown = (e) => {
    e = e || window.event;
    if (e.key === "Escape" || e.key === 27) {
      closeModal();
    }
  }

  if (isDevEnvironment()) {
    console.info(`[DEV] Modal is open`);
  }
}

window.closeModal = () => {
  let modal = document.querySelector('.js-modal');
  document.body.classList.remove('modal-is-open');
  modal.hidden = true;

  document.onkeydown = null;

  if (isDevEnvironment()) {
    console.info(`[DEV] Modal is closed`);
  }
}
// See available options on https://github.com/SortableJS/Sortable#options

/* -----Possibly useful options----- *\

  filter: ".ignore-elements",   // Selectors that do not lead to dragging (String or Function)
  delayOnTouchOnly: false,      // only delay if user is using touch on touchscreen
  direction: 'horizontal',      // Direction of Sortable (will be detected automatically if not given)

\* --------------------------------- */

import Sortable from 'sortablejs';

window.initFormSort = function() {
  let elements = document.querySelectorAll('.form-sort');
  for (const element of elements) {
    Sortable.create(element, {
      handle: ".sortable-handle",
      ghostClass: "sortable-ghost",   // Class name for the drop placeholder
      dragClass: "sortable-drag",     // Class name for the dragging item
      draggable: ".sortable-item",
      animation: 150,
      onEnd: function (evt) {
        let position_fields = evt.item.closest('.form-sort').querySelectorAll('.position-field');
        position_fields.forEach((element, index) => {
          element.value = index;
        })
      }
    });
  }
}

/* ------USAGE------ *\
  ... somewhere in form ...
  <div class='form-sort'>
    <%= f.fields_for :somethings do |ff| %>
      <div class='sortable-item'>
        ...
        <%= ff.hidden_field :position, class: 'position-field' %>
      </div>
    <% end %>
  </div>

  ...

  <script>
    initFormSort();
  </script>

\* ----------------- */


window.initUpdateSort = function() {
  let elements = document.querySelectorAll('.update-sort');
  for (const element of elements) {
    let update_path = element.dataset.updatePath;
    Sortable.create(element, {
      dataIdAttr: 'data-sortable-id',
      handle: ".sortable-handle",
      ghostClass: "sortable-ghost",
      dragClass: "sortable-drag",
      draggable: ".sortable-item",
      animation: 150,
      onUpdate: function (evt) {
        $.ajax({
          url: update_path,
          type: "PATCH",
          data: { ids: [...evt.item.closest('.update-sort').querySelectorAll('[data-sortable-id]')].map((item, index) => item.dataset.sortableId) }
        });
      }
    });
  }
}

/* ------USAGE------ *\

  <div class="update-sort sortable-items" data-update-path="<%= sort_objects_path %>">
    <div class="sortable-item" data-sortable-id="<%= object.id %>">
      <span class="button is-ghost is-icon sortable-handle sortable-icon">
        <span class="icon">
          <%= inline_svg_tag 'icons/drag-hand-gesture.svg' %>
          ...
        </span>
      </span>
    </div>
  </div>

  ...

  <script>
    initUpdateSort();
  </script>

\* ----------------- */

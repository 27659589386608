//PREVENTING OPENING REMOTE LINKS IN NEW TAB
import Rails from "@rails/ujs"

document.addEventListener('turbolinks:load', function() {
  const remoteLinks = Array.from(document.querySelectorAll("a[data-remote='true']"))
  remoteLinks.forEach(function(element) {
    element.dataset.url = element.href
    element.href = "javascript:void(0);"
  })

  Rails.href = function(element) {
    return element.dataset.url || element.href
  }
})